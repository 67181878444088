.sign-in-page {
  padding: 50px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.sign-in-page .form h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.sign-in-page .form {
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #cfcece;
  padding: 20px;
  border-radius: 7px;
}

.input-element label {
  font-size: 16px;
}

.input-element {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 16px;
}

.input-element input,
.input-element select {
  width: 100%;
  height: 50px;
  margin-top: 0px;
  border: 1px solid black;
  outline: none;
  padding: 15px;
  font-size: 19px;
}

.input-element select {
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 10px;
  font-size: 19px;
}

.input-element input:focus {
  border: 2px solid black;
}

.sign-in-page .form .sign-in-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}

 .remember-me {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remember-me input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.sign-in-options a {
  color: #0064e4;
  font-weight: bold;
}

.sign-in-page .form button,
.button-el-classic {
  width: 100%;
  background-color: black;
  outline: none;
  border: 2px solid black;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 25px;
  cursor: pointer;
  transition: 285ms ease-in-out;
  font-weight: 700;
  border-radius: 5px;
}

.sign-in-page .form button:hover,
.button-el-classic:hover {
  background-color: white;
  color: black;
  transition: 285ms ease-in-out;
}

.sign-in-page .form .register {
  margin-top: 10px;
  font-size: 18px;
}

.sign-in-page .form .register a {
  color: #0064e4;
  font-weight: bold;
}

.sign-in-page .form h1.forgot-password-title {
  font-size: 26px;
}

.sign-in-page.register .form {
  width: fit-content;
}

.form .content-wrapper {
  width: 750px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
}

.form .content-wrapper .input-element {
  width: 50%;
}

.privacy-policy-check {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.verification-message {
  text-align: left;
  font-weight: 400;
}

.address-data {
  width: 100%;
  text-align: left;
  margin-top: 20px;
  margin-bottom: -10px;
  font-size: 25px;
}

.mail-note {
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  text-align: left;
}

.mail-note span {
  color: #0064e4;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 6px;
  cursor: pointer;
  margin-right: 6px;
}

.account-created {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  border-top: 1px solid #d1d1d1;
  padding-top: 15px;
}

.account-created button {
  padding: 7px 15px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;
  outline: none;
  transition: 285ms ease-in-out;
}

.order-confirmation-popup.acc-created {
  padding-bottom: 5px !important;
}

.account-created button:hover {
  background-color: #fff;
  color: #000;
  transition: 285ms ease-in-out;
}

.created-btn {
  background-color: #fa0a32 !important;
  border-color: #fa0a32 !important;
}

.created-btn:hover {
  color: #fa0a32 !important;
  background-color: white !important;
}

@media only screen and (max-width: 800px) {
  .sign-in-page .form {
    width: 95% !important;
    border: none;
  }
  .sign-in-page .form .content-wrapper {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  .sign-in-page .form .content-wrapper .input-element {
    width: 100%;
  }
}
