.dashboard {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 45px 0px;
}

.following-header-orders {
  top: 110px !important;
}

.search-product-admin {
  width: 1300px;
  padding: 20px;
  padding-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #cfcece;
}

.admin-inputes {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.admin-content-wrapper {
  width: 24%;
}

.buttons-align {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0px;
}

.buttons-wrp {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.buttons-wrp > button {
  width: 100%;
  border-radius: 0%;
  margin-right: 0px !important;
}

.full-width {
  width: 100% !important;
}

.edit-product-page .form {
  max-width: 800px !important;
}

.add-size-map {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.add-size-map-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: 285ms ease-in-out;
  width: calc(33% - 10px);
  margin: 5px;
}

.sidebar {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 300px;
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: 285ms ease-in-out;
  overflow-x: hidden;
  z-index: 3;
  padding-bottom: 35px;
  overflow-x: hidden;
}

.admin-panel-width {
  width: calc(100% - 300px) !important;
  margin-left: 300px;
  margin-top: 30px;
  /* transition: 285ms ease-in-out; */
}

.admin-logo {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 285ms ease-in-out;
}

.hide-admin-logo-div {
  height: 200px;
  transition: 285ms ease-in-out;
}

.show-admin-logo-div {
  height: 80px;
  transition: 285ms ease-in-out;
}

.admin-links {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.admin-links-section {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.admin-links-section a {
  width: 100%;
  text-decoration: none !important;
}

.set-icons-in-center {
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
}

.admin-icon {
  font-size: 22px;
  color: #fff;
}

.admin-headline {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 9px;
  color: #ffff;
  transition: 285ms ease-in-out;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* background-color: #242424;  */
  padding: 5px 10px;
  text-decoration: none;
}

.content-wrapper-50 {
  width: 49%;
}

.content-wrapper-100 
{
  width: 100%;
}

.active-link {
  background-color: #fa0a32;
  color: #000;
  border-radius: 5px;
  transition: 100ms ease-in-out;
}

.admin-links-section.set-icons-in-center a.active-link {
  border-radius: 0px !important;
}

.first-headline {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.last-headline {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.address-data h3,
#admin-panel > section > div.product-images-section > h3 {
  font-size: 25px;
}

.show-icon-admin {
  color: #ffff;
  transition: 285ms ease-in-out;
}

.hide-icon-admin {
  color: #fff;
  transition: 285ms ease-in-out;
}

.admin-links-mapping {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* margin-top: 10px; */
  padding-left: 35px;
  gap: 5px;
}

.admin-links-mapping a {
  color: #ffff;
  font-size: 17px;
  text-decoration: none;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.break-long-word {
  word-break: break-all;
}

.admin-links-hover-title {
  font-size: 20px;
}

.w-img {
  position: relative;
  width: 100%;
}

.disable-image-upload {
  width: 100%;
  min-height: 100%;
  height: fit-content;
  background: rgba(243, 242, 242, 0.9);
  display: flex;
  z-index: 9;
  position: absolute;
  top: 10px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  text-align: center;
}

.danger-txt {
  color: #fa0a32;
  text-decoration: underline;
  cursor: pointer;
}

.admin-links-children {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #929292;
  padding-top: 10px;
  margin-top: 10px;
  width: 100%;
}

.admin-links-children a {
  color: #000;
  font-size: 17px;
  text-decoration: none;
}

.admin-logo-img {
  width: 150px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 285ms ease-in-out;
}

.show-logo-admin {
  width: 40px;
  height: fit-content;
  transition: 285ms ease-in-out;
}

.hide-logo-admin {
  width: 150px;
  height: fit-content;
  transition: 285ms ease-in-out;
}

.admin-header {
  position: fixed;
  top: 0px;
  left: 300px;
  width: calc(100% - 300px);
  background-color: #f1f1f1;
  border-bottom: 1px solid #cfcece;
  height: 65px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.hide-item-admin {
  display: none;
  transition: 285ms ease-in-out;
}

.show-item-admin {
  transition: 285ms ease-in-out;
  display: flex;
}

.colapse-sidebar {
  width: 60px;
  height: 45px;
  color: #fff;
  font-size: 30px;
  border: 2px solid #000;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  cursor: pointer;
  transition: 285ms ease-in-out;
  border-radius: 4px;
}

.admin-links-hover {
  background-color: #fff;
  border-top: 1px solid #929292;
  border-right: 1px solid #929292;
  border-bottom: 1px solid #929292;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 250px;
  z-index: 99;
  position: fixed;
}

.total-size-wrp {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.add-product-images {
  width: 100%;
  height: 80px;
  border: 2px dashed #cfcece;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.add-product-images input[type='file'] {
  width: 100%;
  height: 80px;
  opacity: 0;
  cursor: pointer;
}

.img-ic {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-images-section {
  width: 100%;
  text-align: left;
  margin-top: 20px;
  margin-bottom: -10px;
  font-size: 25px;
}

.product-images-list {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
}

.no-product-image {
  width: 160px;
  font-size: 75px;
}

.admin-main-checkout {
  justify-content: flex-start !important;
  width: 480px !important;
}

.admin-checkout-title {
  width: 100% !important;
  max-width: 100% !important;
}

.product-images-list img {
  width: 120px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #333333;
  cursor: pointer;
}

.admin-product {
  margin-top: 0px !important;
  width: 1300px !important;
  padding: 5px 0px;
  height: 190px;
}

.checkout-admin-product {
  width: 200px !important;
}

.remove-img-wrp {
  position: relative;
}

.remove-image-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  background-color: #fa0a32;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 30px;
  transition: 285ms ease-in-out;
}

.remove-image-btn:hover {
  transform: scale(1.15);
  transition: 285ms ease-in-out;
}

.size-item {
  background-color: #e6e6e6;
  padding: 4px;
  width: 75px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-size-wrapper {
  width: 240px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.size-wrapper {
  width: 240px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  margin-top: 5px;
}

.edit-product-btn {
  padding: 10px;
  font-size: 45px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  background-color: #0064e4;
  border: 2px solid #0064e4;
  transition: 285ms ease-in-out;
}

.edit-product-btn:hover {
  transition: 285ms ease-in-out;
  background-color: white;
  color: #0064e4;
  transform: scale(1.15);
}

.other-product-data {
  width: 110px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.coupons-mapper {
  width: 1300px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.admin-prices {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 4px;
}

.user-data-wrapper {
  width: 1300px;
}

.admin-prices h2 {
  font-size: 20px;
  margin-top: -5px;
}

.discount-data-map {
  margin-top: 5px;
}

.product-status {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 3px;
  margin-top: 10px;
  width: 120px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.admin-prouduct-primary-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 13px;
}

.admin-prices {
  margin-bottom: 5px;
}

.quantity-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.expand-word-width {
  width: 100%;
}

.statistics {
  display: flex;
  justify-content: center;
  align-items: center;
}

.statistics h3 {
  width: 95px;
}

.admin-product-image {
  max-height: 160px;
}

.statistics h3,
.statistics h4 {
  font-size: 17px;
  line-height: 20px;
}

.inline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.backoffice-options {
  width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cfcece;
  padding: 10px 0px;
  background-color: white;
  position: sticky;
  top: 64.5px;
  z-index: 1;
}

.add-new-btn {
  border-radius: 0px;
  width: 250px !important;
  margin: 0px !important;
  height: 54px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-block {
  font-size: 20px;
  height: 54px;
  width: 54px;
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #cfcece;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000 !important;
}

button.pagination-block {
  background-color: #cfcece;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.blue-btn {
  background-color: #0064e4 !important;
  border-color: #0064e4 !important;
}

.blue-btn:hover {
  background-color: transparent !important;
  color: #0064e4 !important;
}

button.pagination-block:hover {
  background-color: #b6b5b5;
  border: 1px solid #b6b5b5;
  transition: 285ms ease-in-out;
}

div.pagination-block {
  min-width: 54px;
  max-width: fit-content;
}

.space-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.results-per-page {
  margin-left: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.results-per-page select {
  border: 1px solid #cfcece;
  height: 54px;
  font-size: 20px;
  margin-left: 10px;
  width: 100px;
  outline: none !important;
}

.results-per-page.sort select {
  width: 230px !important;
}

.user-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.user-table td,
.user-table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
}

.product-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.product-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.product-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.product-switch .slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.align-end-btn {
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.product-switch input:checked + .slider {
  background-color: #000;
}

.product-switch input:focus + .slider {
  box-shadow: 0 0 1px #000;
}

.product-switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.product-switch .slider.round {
  border-radius: 34px;
}

.product-switch .slider.round:before {
  border-radius: 50%;
}

input[type='radio'] {
  accent-color: #000;
}

.toggle-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.toggle-wrapper h2 {
  font-size: 25px;
  margin-bottom: -20px !important;
}

.orders-map {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px;
  width: 100%;
}

.orders-map-data-wrapper {
  width: 1300px;
}

.primary-order-data {
  width: 100%;
  background-color: #e6e6e6;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.order-data-admin {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.toggle-switch-wrapper-width {
  width: fit-content !important;
}

.toggle-wrapper-no-marg {
  margin-top: 0px !important;
}

.order-data-admin .contact-data-order-admin-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.order-item-width-admin {
  width: 100%;
}

.contact-data-order-admin {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 350px;
  width: 350px;
}

.order-note-admin {
  width: 250px;
  max-width: 250px !important;
}

.contact-data-order-admin h1 {
  font-size: 25px;
}

.remove-top-padding {
  padding-top: 0px;
}

.contact-data-order-admin h3 {
  font-size: 17px;
}

.align-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.order-button {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
  width: 150px;
  padding: 10px 0px;
  font-size: 17px;
  cursor: pointer;
  font-weight: bold;
  transition: 285ms ease-in-out;
}

.order-button:hover {
  transition: 285ms ease-in-out;
  background-color: #e6e6e6;
  color: #000;
}

option[value=''][disabled] {
  background-color: #cfcece;
}
.admin .cart-options-2 div {
  border: 1px solid #cfcece;
}

.admin .table-size,
.admin .cart-options-2 div {
  font-size: 17px !important;
  font-weight: bold !important;
}

.orders-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.users-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.contact-data-order-admin.user-admin-data {
  width: 380px;
  max-width: 380px;
}

.user-role-tag {
  padding: 5px;
  color: white;
  margin-bottom: 5px;
}

.newsletter-subscribers-search {
  gap: 25px;
}

.toggle-switch-title {
  width: 75% !important;
}

.product-mobile-right-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.admin-column {
  flex-direction: column !important;
  gap: 0px !important;
}

.edit-order-btn {
  background-color: #fa0a32;
  border: 2px solid #fa0a32;
}

.following-header select {
  background-color: #fff !important;
  color: #000 !important;
}

.edit-order-btn:hover {
  color: #fa0a32;
}

.loading-screen {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.85);
}

.loading-icon {
  width: 130px;
  height: 130px;
  font-size: 130px;
  color: #fff;
  animation: spin 2s linear infinite;
  position: absolute;
  z-index: 9999;
}

.show-loading {
  display: flex;
  animation: showLoading 285ms ease-in-out;
}

.hide-loading {
  display: none;
  animation: hideLoading 285ms ease-in-out;
}

@keyframes showLoading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hideLoading {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.buttons-delete button {
  width: 120px;
  padding: 5px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;
  outline: none;
  transition: 285ms ease-in-out;
}

.buttons-delete button:hover {
  background-color: #fff;
  color: #000;
  transition: 285ms ease-in-out;
}

.buttons-delete button.delete-btn {
  background-color: #fa0a32;
  border: 2px solid #fa0a32;
}

.coupon-discount {
  background-color: #fa0a32;
  color: #ffff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0px;
  width: 70px;
}

.admin-nav-mobile {
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.product-btns-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.product-admin-actions {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.delete-product {
  background-color: #fa0a32 !important;
  color: #fff;
  border: 1px solid #fa0a32 !important;
}

.delete-product:hover {
  background-color: #ffff !important;
  color: #fa0a32 !important;
  fill: #fa0a32 !important;
}

.server-delete-data {
  width: 100%;
  margin-top: 4px;
  border-collapse: collapse;
}

.server-delete-data td {
  width: 50%;
  padding-top: 3px;
  padding-bottom: 3px;
  border-top: 1px solid #929292;
  border-bottom: 1px solid #929292;
  font-size: 15px;
}

.admin-nav-mobile .admin-headline {
  font-size: 10px;
  padding: 8px 10px;
}

.admin-nav-mobile .admin-headline,
.admin-nav-mobile .admin-icon {
  color: #000 !important;
}

.primary-coupon-width {
  width: 300px !important;
  max-width: 400px !important;
}

.buyer-search-top {
  top: 42px !important;
}

.align-end {
  width: 1100px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 0px;
}

.buttons-delete button.delete-btn:hover {
  background-color: #fff;
  color: #fa0a32;
  transition: 285ms ease-in-out;
}

.delete-acc-modal {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 400px;
  padding-top: 20px;
}

.delete-acc-modal p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  text-align: justify;
}

.buttons-delete {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.order-note-txt {
  height: 100px;
  overflow-y: auto;
  max-width: 95%;
}

.coupons-block {
  padding-bottom: 50px;
}

.mobile-view-orders {
  display: none;
}

.product-image-block-admin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-image-block-admin input[type='radio'] {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.show-content-admin {
  width: calc(100% - 50px) !important;
  margin-left: 50px !important;
}

.hide-content-admin {
  width: calc(100% - 300px) !important;
  margin-left: 300px !important;
}

.hide-icon-admin:hover {
  cursor: pointer;
  opacity: 0.5;
}

.admin-acc-data {
  color: #000;
  text-decoration: none !important;
  padding: 10px;
  background-color: #dbdbdb;
  border-radius: 5px;
}

.admin-acc-data a {
  text-decoration: none;
  color: #000;
}

.static-show-content-admin {
  width: calc(100% - 50px) !important;
  margin-left: 50px !important;
}

.remove-trans {
  transition: none !important;
}

.add-trans {
  transition: 285ms ease-in-out !important;
}

.show-header-admin {
  width: calc(100% - 50px) !important;
  left: 50px !important;
  transition: 285ms ease-in-out;
}

.hide-header-admin {
  width: calc(100% - 300px) !important;
  left: 300px !important;
  transition: 285ms ease-in-out;
}

.search-product-admin .store-search-block {
  border-bottom: none !important;
}

.search-product-admin .store-headline {
  border-bottom: 1px solid black;
}

.search-product-admin .store-headline h3 {
  font-size: 30px;
}

@media only screen and (max-width: 1700px) and (min-width: 1000px) {
  .search-product-admin,
  .backoffice-options,
  .orders-map-data-wrapper,
  .users-list,
  .admin-product {
    width: 95% !important;
  }

  .user-data-wrapper {
    width: 100% !important;
  }

  .contact-data-order-admin {
    max-width: 48%;
    width: 48%;
  }

  .contact-data-order-admin-wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 1000px) {
  .orders-map-data-wrapper,
  .backoffice-options {
    width: 100%;
  }

  .total-size-wrp {
    max-height: unset;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1000px) {
  .admin-panel-width {
    width: calc(100% - 250px) !important;
    margin-left: 250px;
    margin-top: 30px;
  }

  .hide-header-admin {
    width: calc(100% - 250px) !important;
    left: 250px !important;
  }

  .show-content-admin {
    width: calc(100% - 50px) !important;
    margin-left: 50px !important;
  }

  .hide-content-admin {
    width: calc(100% - 250px) !important;
    margin-left: 250px !important;
  }

  .sidebar {
    width: 250px;
  }

  .results-per-page {
    font-size: 15px;
  }

  .admin-header {
    left: 250px;
    width: calc(100% - 250px);
  }

  .hide-content-admin {
    width: calc(100% - 250px);
    margin-left: 250px;
  }

  .admin-main-checkout {
    width: 380px !important;
  }
}

@media only screen and (max-width: 1330px) {
  .dashboard {
    width: 95%;
  }
  .orders-map-data-wrapper .order-data-admin {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .orders-map-data-wrapper .order-data-admin .contact-data-order-admin-wrapper {
    width: 100%;
  }
  .orders-map-data-wrapper .contact-data-order-admin {
    width: 40%;
    max-width: 40%;
    margin: 10px 0;
  }
  .order-note-admin {
    width: 100%;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 800px) {
  .dashboard {
    justify-content: center;
  }
  .mobile-view-orders select {
    height: 45px;
  }

  .mobile-view-orders p {
    font-size: 15px;
  }
  .search-product-admin {
    justify-content: center;
    align-items: center;
  }
  .search-product-admin .buttons-align {
    justify-content: center;
    align-items: center;
  }
  .admin-inputes {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .admin-inputes .admin-content-wrapper {
    width: 100%;
  }
  .backoffice-options {
    justify-content: center;
    flex-direction: column;
    position: initial;
    z-index: 1;
  }

  .wishlist-header {
    flex-direction: row !important;
    justify-content: space-between !important;
    border-bottom: 1px solid #cfcece !important;
  }

  .wishlist-header h1 {
    font-size: 20px;
  }

  .wishlist-header .delete-all-saved {
    height: 45px;
    width: 150px;
    font-size: 17px;
  }

  .wishlist-dashboard {
    justify-content: flex-start !important;
  }

  .backoffice-options .space-items {
    flex-direction: column;
    margin-bottom: 5px;
  }
  .backoffice-options .space-items div {
    margin: 5px 0;
  }
  .backoffice-options .results-per-page {
    margin-left: 0;
  }
  .orders-map {
    justify-content: center;
  }
  .orders-map .empty-shoppinh-cart {
    text-align: center;
  }

  .mobile-view-orders {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .mobile-view-orders select {
    width: 100%;
    margin-left: 0px;
  }

  .desktop-view {
    display: none;
  }

  .dashboard {
    padding-top: 0px;
  }

  .following-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    position: -webkit-sticky;
    align-self: flex-start;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 112px;
    z-index: 1;
    background-color: white;
    padding: 10px 0px;
    border-bottom: 1px solid black;
  }

  .backoffice-options {
    border-bottom: none;
  }

  .following-header select {
    font-size: 17px;
    width: 150px !important;
    height: 45px !important;
    border: 1px solid #cfcece;
    outline: none !important;
    background-color: #fff !important;
  }

  .following-header .pagination-block {
    font-size: 17px;
    height: 45px;
    width: 45px;
    border: 1px solid #cfcece;
  }
}

@media only screen and (max-width: 600px) {
  .orders-map-data-wrapper .primary-order-data {
    justify-content: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .orders-map-data-wrapper .contact-data-order-admin {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 470px) {
  .orders-map-data-wrapper .order-data-admin .contact-data-order-admin-wrapper {
    flex-direction: column;
  }
  .orders-map-data-wrapper .contact-data-order-admin {
    width: 100%;
    max-width: 100%;
  }

  .delete-acc-modal {
    width: 90%;
  }

  .buttons-delete button {
    font-size: 15px;
    width: 50%;
  }

  .order-content {
    width: 100%;
  }

  .toggle-order-button {
    width: 100% !important;
  }

  .order-note-txt {
    height: fit-content;
    max-height: 100px;
  }

  .contact-data-order-admin h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .contact-data-order-admin h3 {
    font-size: 15px;
  }

  .orders-map-data-wrapper .contact-data-order-admin {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .order-status-info {
    font-size: 23px;
  }

  .order-status-info-svg {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1370px) {
  .add-new-btn {
    font-size: 14px;
    width: 180px !important;
    height: 54px;
  }
}

@media only screen and (max-width: 1400px) {
  .align-end {
    width: 95% !important;
  }
}

@media only screen and (max-width: 1600px) {
  .coupons-mapper {
    width: 95% !important;
  }

  .admin-links {
    gap: 5px;
  }
}

@media only screen and (max-width: 1330px) and (min-width: 1000px) {
  .size-item {
    width: 60px;
    font-size: 12px;
    padding: 3px;
  }

  .other-product-data {
    width: 100px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .size-wrapper {
    width: 190px;
  }

  .admin-prices h2 {
    font-size: 15px !important;
  }

  .statistics h3,
  .statistics h4 {
    font-size: 14px;
    line-height: 17px;
  }

  .statistics h3 {
    width: 70px;
  }

  .product-status {
    font-size: 11px;
    margin-top: 10px;
    width: 90px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .edit-product-btn {
    padding: 6px;
    font-size: 35px;
    margin-left: 20px;
    border-radius: 3px;
  }

  .admin-prouduct-primary-data .checkout-code {
    font-size: 12px !important;
  }

  .admin-checkout-title {
    font-size: 15px !important;
  }

  .total-size-wrp h3 {
    font-size: 14px;
  }

  .admin-main-checkout {
    width: 400px !important;
  }

  .no-product-image {
    width: 130px !important;
  }

  .admin-product {
    height: 150px;
  }
}

.admin-backoffice-top {
  display: none !important;
}

@media only screen and (max-width: 1000px) {
  .sidebar {
    display: none;
  }

  .add-page-form {
    width: 95% !important;
  }

  .admin-panel-width {
    margin-left: 0px;
  }

  /* .edit-product-page .form {
    width: fit-content !important;
  } */

  .backoffice-options {
    z-index: 1;
    position: initial;
    top: 115px;
  }

  .admin-following-header {
    position: -webkit-sticky;
    align-self: flex-start;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    top: 70px;
    z-index: 1;
  }

  .newsletter-subscribers-search.admin-search-mobile {
    padding: 0px !important;
  }

  .search-product-admin .open-list {
    max-height: 620px;
  }

  .dashboard {
    padding-top: 0px;
  }

  .admin-header {
    width: 100% !important;
    position: fixed;
    top: 0px;
    z-index: 9;
    left: 0px !important;
  }

  .admin-panel-width {
    width: 100% !important;
  }

  #root {
    justify-content: flex-start;
  }

  .wishlist-header {
    padding-bottom: 10px !important;
  }

  .search-product-admin,
  .hide-content-admin {
    width: 97%;
    margin-left: 0px !important;
  }

  .admin-content-wrapper {
    width: calc(50% - 10px) !important;
  }

  .admin-inputes {
    flex-wrap: wrap;
  }

  .add-new-btn {
    width: 100% !important;
    font-size: 17px !important;
  }

  .admin-backoffice-top {
    display: flex !important;
    flex-direction: column;
    overflow: auto;
    width: 97%;
    padding-bottom: 0px;
  }

  .backoffice-options {
    padding-bottom: 0px;
  }

  .admin-desktop-sort-options {
    display: none !important;
  }

  .admin-full-width {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 8px;
  }

  .admin-full-width select {
    width: 100%;
    height: 45px;
    font-size: 15px;
  }

  .admin-full-width p {
    color: #333333;
  }

  .admin-panel-width {
    margin-top: 10px;
  }

  .search-product-admin .store-headline svg {
    width: 20px !important;
    height: 20px !important;
  }

  .admin-top-following-header {
    top: 70px;
  }

  .admin-top-margin-acc {
    position: absolute;
    top: 70px;
  }

  .newsletter-subscribers .order-data-admin {
    padding-top: 5px;
  }

  .newsletter-subscribers .edit-order-btn {
    font-size: 20px !important;
  }

  .newsletter-subscribers .user-data-wrapper .primary-order-data {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
  }

  .admin-pagination-and-sorting {
    width: 97%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-top: 10px;
    position: -webkit-sticky;
    margin-left: auto;
    margin-right: auto;
    position: sticky !important;
    top: 72px;
    background-color: #fff;
    z-index: 1;
    align-self: flex-start !important;
  }

  .admin-pagination-and-sorting select {
    height: 45px;
    width: 250px;
    font-size: 15px;
  }

  .admin-pagination-and-sorting .pagination-block,
  .admin-pagination-and-sorting .pagination-block div {
    width: 45px !important;
    height: 45px !important;
  }

  .mobile-admin-inputs {
    flex-direction: row !important;
    column-gap: 10px;
  }

  .admin-search-mobile {
    margin-top: 0px;
    padding: 10px;
  }

  .admin-search-mobile h1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #929292;
    padding-bottom: 5px;
    font-size: 18px;
  }

  .admin-search-mobile .input-element label {
    font-size: 13px;
  }

  .reset-btn-admin {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;
    margin-top: 15px;
  }

  .admin-search-mobile .input-element select,
  .admin-search-mobile .input-element input {
    width: 100%;
    height: 45px;
    margin-top: 2px;
    font-size: 13px;
    line-height: 45px;
  }

  .admin-search-mobile .input-element {
    margin-top: 5px;
  }

  .admin-product-mobile {
    width: 97%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 1px solid #000;
  }

  .headline-and-category {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #b6b5b5;
  }

  .mobile-product-titles {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-width: calc(100% - 50px);
  }

  .mobile-product-titles h1 {
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    max-width: 100%;
  }

  .mobile-product-titles h3 {
    color: #929292;
    font-size: 15px;
    line-height: 19px;
    max-width: 100%;
  }

  .edit-product-btn-mobile {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 3px;
    background-color: #0064e4;
    color: #ffff;
  }

  .image-and-base-product-info-admin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #b6b5b5;
    gap: 15px;
  }

  .admin-product-image-mobile,
  .no-image-mobile {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }

  .no-image-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .admin-product-info-base {
    width: calc(100% - 165px);
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .mobile-base-info {
    font-size: 14px;
    font-weight: 600;
    color: #303030;
  }

  .admin-product-sizes-mobile {
    border-bottom: 1px solid #b6b5b5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .admin-product-sizes-mobile h1 {
    font-size: 18px;
  }

  .admin-product-sizes-mobile-mapping {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 5px;
  }

  .prices-mobile-product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #b6b5b5;
  }

  .current-price-mobile {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .prices-mobile-product .checkout-discount {
    margin: 0px !important;
  }

  .product-statistics-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f0f0f0;
  }

  .statistics-mobile-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
  }

  .statistic-mobile-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .statistic-mobile-data h3 {
    font-size: 15px;
    line-height: 19px;
    width: 90px;
  }

  .admin-search-mobile .admin-inputes {
    flex-direction: row !important;
  }

  .orders-map,
  .orders-admin-width,
  .users-list,
  .users-backoffice {
    width: 97%;
  }

  .user-data-wrapper {
    width: 100%;
  }

  .edit-product-page {
    padding-top: 10px;
    padding-bottom: 80px;
    margin-top: 0px !important;
  }

  .edit-product-page .form .content-wrapper {
    width: 100%;
  }

  .edit-product-page .form h1 {
    font-size: 25px;
  }

  .edit-product-page .input-element {
    margin-top: 5px;
  }

  .edit-product-page .input-element input,
  .edit-product-page .input-element select {
    margin-top: 0px;
    height: 45px;
    font-size: 15px;
    padding-left: 5px !important;
  }

  .edit-product-page .input-element label {
    font-size: 15px;
  }

  .toggle-wrapper h2 {
    font-size: 19px;
    line-height: 19px;
    margin-bottom: -5px !important;
  }

  .toggle-wrapper {
    margin-top: 7px;
    padding-bottom: 10px;
    padding-top: 5px;
  }

  .product-switch {
    width: 50px;
    height: 30px;
  }

  .product-switch .slider:before {
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
  }

  .product-switch input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  .edit-product-page .address-data h3,
  .edit-product-page .product-images-section h3 {
    font-size: 22px;
  }

  .product-images-list {
    gap: 15px;
  }

  .edit-product-page .add-size-map-button {
    font-size: 13px;
  }

  .add-product-page .add-size-map-button {
    width: 100% !important;
  }

  .product-images-list {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .search-product-admin .store-headline h3 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 500px) {
  .product-image-block-admin {
    width: calc(33% - 15px);
  }

  .product-images-list img {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .add-new-btn {
    font-size: 15px !important;
    height: 45px;
  }

  .product-image-block-admin {
    width: calc(50% - 15px);
  }

  .product-images-list img {
    width: 100%;
  }

  .buttons-wrp {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .reset-btn-admin {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 17px;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .admin-pagination-and-sorting select {
    height: 45px;
    width: 100%;
    font-size: 15px;
  }

  .order-content {
    flex-direction: column;
  }

  .edit-order-btn {
    width: 100%;
  }

  .admin-content-wrapper {
    width: 100% !important;
  }
}

@media only screen and (max-width: 650px) {
  .mobile-coupon {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .blue-btn:hover {
    background-color: #0064e4 !important;
    color: #fff !important;
  }

  .discount-and-data-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .discount-and-data-mobile h1 {
    font-size: 17px;
  }

  .discount-and-data-mobile h3 {
    font-size: 15px;
  }

  .edit-coupon-mobile {
    width: 100%;
  }

  .coupon-discount {
    font-size: 15px;
    width: 60px;
  }

  .add-new-coupon {
    width: 100%;
  }

  .edit-coupon-mobile,
  .add-new-coupon,
  .edit-order-btn,
  .toggle-order-button {
    padding: 5px 0px !important;
  }

  .add-size-add-page {
    width: 100% !important;
  }
}

@media only screen and (max-width: 750px) {
  .add-size-map {
    width: 100%;
  }

  .add-size-map-button {
    width: calc(50% - 10px);
    height: 45px;
  }

  .add-product-page .add-size-map-button {
    height: 36px;
  }

  .user-data-wrapper .primary-order-data {
    flex-direction: column !important;
    padding: 5px !important;
  }

  .user-data-wrapper .primary-order-data .align-items {
    width: 100%;
    gap: 10px;
    margin-top: 5px;
  }

  .user-data-wrapper .primary-order-data .align-items:nth-child(2) {
    flex-direction: column !important;
    gap: 5px !important;
  }

  .user-data-wrapper .primary-order-data .align-items button {
    width: 100%;
    height: 45px !important;
    font-size: 15px !important;
  }

  .user-data-wrapper .primary-order-data .align-items h1 {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
  }

  .user-data-wrapper .order-data-admin {
    flex-direction: column;
  }

  .user-data-wrapper .contact-data-order-admin.user-admin-data {
    margin-top: 5px;
    width: 100%;
  }

  .user-data-wrapper .contact-data-order-admin.user-admin-data h1 {
    font-size: 21px;
  }

  .user-data-wrapper .contact-data-order-admin.user-admin-data h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1000px) {
  /* .admin-inputes .input-element input,
  .admin-inputes .input-element select {
    height: 45px !important;
    font-size: 15px !important;
    padding-left: 8px !important;
    margin-top: 0px !important;
  } */

  .admin-inputes .input-element label,
  .admin-desktop-sort-options select,
  .results-per-page p {
    font-size: 17px !important;
  }

  .reset-btn-admin {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 17px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
    transition: 285ms ease-in-out;
    font-weight: 700;
    border-radius: 7px;
    height: 50px;
  }

  .buttons-wrp {
    width: 200px;
  }

  .search-product-admin .store-headline h3 {
    font-size: 25px;
  }

  /* .admin-desktop-sort-options select,
  .admin-desktop-sort-options button,
  .admin-desktop-sort-options .pagination-block {
    height: 45px !important;
  }

  .admin-desktop-sort-options .pagination-block {
    width: 45px !important;
  } */

  .results-per-page.sort select {
    width: 200px !important;
  }

  .add-new-btn {
    width: 230px !important;
    font-size: 16px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 54px;
  }
}
