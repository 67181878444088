.account {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.account .form {
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #cfcece;
  padding: 20px;
  border-radius: 7px;
}

.account .form img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.account .form h1 {
  font-size: 20px;
}

.account .form h2 {
  font-size: 15px;
}

.account .form .primary {
  text-align: center;
}

.account .form .contact-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 25px;
}

.account .form .contact-info .contact-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.account .form .contact-info .headline h3 {
  color: #65696e;
  margin-right: 8px;
}

.account .form .contact-info .contact-detail .icon {
  color: white;
  background-color: black;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.account .form .contact-info .contact-detail p {
  font-size: 18px;
  font-weight: bold;
}

.info-mapping {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10px;
}

.info-mapping small {
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
  color: #65696e;
}

.account .form .contact-info .headline {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.account .form .contact-info .headline button {
  padding: 4px 12px;
  color: white;
  background-color: #0064e4;
  border-radius: 20px;
  border: 2px solid #0064e4;
  outline: navajowhite;
  cursor: pointer;
  transition: 285ms ease-in-out;
  font-weight: bold;
}

.account .form .contact-info .headline button:hover {
  background-color: white;
  color: #0064e4;
  transition: 285ms ease-in-out;
}

.account .form .contact-info .headline .first-edit {
  margin-left: 6px;
}

.account .form .options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.account .form button {
  width: 48%;
  border-radius: 5px;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 17px;
  cursor: pointer;
}

.account .form .options .sign-out {
  background-color: #fa0a32;
  border: 2px solid #fa0a32;
  transition: 285ms ease-in-out;
}

.account .form .options .change-password {
  background-color: #0064e4;
  border: 2px solid #0064e4;
  transition: 285ms ease-in-out;
}

.account .form .options .sign-out:hover {
  background-color: #fff;
  color: #fa0a32;
  transition: 285ms ease-in-out;
}

.account .form .options .change-password:hover {
  background-color: #fff;
  color: #0064e4;
  transition: 285ms ease-in-out;
}

.account .form .account-edit {
  width: 100%;
  margin-top: 5px;
  background-color: #0064e4;
  border: 2px solid #0064e4;
  transition: 285ms ease-in-out;
}

.account .form .account-edit:hover {
  background-color: #fff;
  color: #0064e4;
  transition: 285ms ease-in-out;
}

.inline-data {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row !important;
  width: 100%;
}

.inline-data .content-wrapper {
  width: 48%;
  margin: 10px;
}

.edit-account-modal {
  width: 670px;
}

.save-edit-acc-btn {
  width: 620px !important;
}

@media only screen and (max-width: 800px) {
  .edit-account-modal {
    width: 100%;
  }

  .account .form .options {
    justify-content: center;
    flex-direction: column;
  }

  .account .form .options button {
    width: 100%;
    margin-top: 5px;
  }

  .edit-account-modal .inline-data {
    flex-direction: column !important;
  }

  .edit-account-modal .inline-data .content-wrapper {
    width: 95%;
  }

  .edit-account-modal .save-edit-acc-btn {
    width: 90% !important;
  }

  .sign-in-page {
    padding: 20px 0px;
  }

  .form .content-wrapper {
    gap: 0px;
  }

  .sign-in-page .form {
    padding: 5px;
  }
}

@media only screen and (max-width: 480px) {
  .edit-account-modal .inline-data {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: flex-start;
  }

  .account .form {
    border: none;
  }

  .sign-in-page .form h1 {
    font-size: 30px;
  }

  .account {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}
