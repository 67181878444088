.home-page {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.product-type {
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
}

.product-type,
.product-type-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-type-data {
  margin: 20px;
  margin-bottom: 0px;
  flex-direction: column;
  background-color: #000;
  color: #fff;
}

.product-type img,
.img-wrapper {
  width: 400px;
  height: 400px;
  /* border-radius: 10px; */
  transform: scale(1);
  transition: 285ms ease-in-out;
}

.img-wrapper {
  max-width: 400px;
  max-height: 400px;
  overflow: hidden;
  /* border-radius: 10px; */
}

.product-type-data h3 {
  font-size: 25px;
  text-decoration: none;
  transition: 285ms ease-in-out;
  padding-top: 5px;
  padding-bottom: 5px;
}

.product-type-data:hover img {
  cursor: pointer;
  transform: scale(1.1);
  transition: 285ms ease-in-out;
}

.home-title-for-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-related-wrapper {
  padding-top: 10px;
  margin-top: 0px !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sixth-img {
  display: none;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: calc(100vh - 250px);
  object-fit: cover;
}

.swiper-pagination-bullet-active {
  background-color: #fa0a32 !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #fa0a32 !important;
}

.posters-wrp {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 0px;
  margin-bottom: 35px;
  width: 100%;
  background-color: #000;
}

.main-poster {
  width: 680px;
  height: 815px;
}

.secondary-poster {
  width: 400px;
  height: 400px;
}

.posters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  margin-left: 15px;
}

.side-bar-images {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 35px 0px;
}

.side-image {
  width: 49%;
  height: 450px;
  display: flex;
  overflow: hidden;
}

.side-image-tag {
  width: 100%;
  height: 450px;
  transition: 285ms ease-in-out;
  cursor: pointer;
}

@media only screen and (max-width: 1800px) and (min-width: 1600px) {
  .side-image,
  .side-image-tag {
    height: 400px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1400px) {
  .side-image,
  .side-image-tag {
    height: 370px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1230px) {
  .side-image,
  .side-image-tag {
    height: 350px;
  }
}

@media only screen and (max-width: 1230px) and (min-width: 1130px) {
  .side-image,
  .side-image-tag {
    height: 330px;
  }
}

@media only screen and (max-width: 1130px) and (min-width: 1000px) {
  .side-image,
  .side-image-tag {
    height: 285px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 800px) {
  .side-image,
  .side-image-tag {
    height: 250px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 700px) {
  .side-image,
  .side-image-tag {
    height: 200px;
  }
}

@media only screen and (max-width: 700px) and (min-width: 600px) {
  .side-image,
  .side-image-tag {
    height: 220px;
  }
}

@media only screen and (max-width: 600px) and (min-width: 400px) {
  .side-image,
  .side-image-tag {
    height: 200px;
  }
}


@media only screen and (max-width: 500px) and (min-width: 400px) {
  .side-image,
  .side-image-tag {
    height: 175px;
  }
}

@media only screen and (max-width: 400px) and (min-width: 300px) {
  .side-image,
  .side-image-tag {
    height: 145px;
  }
}


.side-image-tag:hover {
  transform: scale(1.1);
  transition: 285ms ease-in-out;
}

.discount-image {
  width: 80%;
  height: 400px;
  margin-top: 35px;
  overflow: hidden;
}

.discounted-img-element {
  width: 100%;
  height: 100%;
  transition: 285ms ease-in-out;
  object-fit: cover;
  cursor: pointer;
}

.discounted-img-element:hover {
  transform: scale(1.1);
}

.services {
  width: 100%;
  background-color: #000;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.service {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.icon-service {
  font-size: 70px;
}

.service p {
  width: 250px;
  font-size: 20px;
}

.insta-mapping {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 35px;
  gap: 20px;
}

.insta-title {
  font-size: 50px !important;
  color: black;
  text-decoration: none;
  font-weight: 600;
}

.insta-img {
  width: calc(20% - 20px);
}

@media only screen and (max-width: 1600px) and (min-width: 768px) {
  .discount-image {
    width: 90%;
  }
}

@media only screen and (max-width: 1300px) {
  .swiper-slide img {
    height: fit-content;
  }

  .product-type img,
  .img-wrapper {
    width: 250px;
    height: 250px;
  }

  .img-wrapper {
    max-width: 250px;
    max-height: 250px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .posters-wrp {
    padding: 30px 0px;
    margin-bottom: 35px;
    width: 100%;
  }

  .main-poster {
    width: 530px;
    height: 680px;
  }

  .secondary-poster {
    width: 335px;
    height: 335px;
  }

  .posters {
    gap: 14px;
    margin-left: 15px;
  }

  .services {
    width: 100%;
    padding: 20px 10px;
    gap: 30px;
  }

  .service {
    color: #fff;
    gap: 15px;
  }

  .icon-service {
    font-size: 60px;
  }

  .service p {
    width: 200px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .services {
    flex-direction: column;
    gap: 10px;
  }

  .products-bottom-margin {
    margin-bottom: 15px !important;
  }
}

@media only screen and (max-width: 900px) and (min-width: 650px) {
  .posters-wrp {
    padding: 30px 0px;
    margin-bottom: 35px;
    width: 100%;
  }

  .main-poster {
    width: 380px;
    height: 480px;
  }

  .secondary-poster {
    width: 235px;
    height: 235px;
  }

  .posters {
    gap: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 650px) and (min-width: 520px) {
  .posters-wrp {
    padding: 30px 0px;
    margin-bottom: 35px;
    width: 100%;
  }

  .main-poster {
    width: 300px;
    height: 400px;
  }

  .secondary-poster {
    width: 195px;
    height: 195px;
  }

  .main-poster {
    width: 60%;
    height: fit-content;
  }

  .secondary-poster {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
  }

  .posters {
    gap: 5px;
    margin-left: 5px;
    width: 37.1%;
  }
}

@media only screen and (max-width: 520px) and (min-width: 300px) {
  .posters-wrp {
    padding: 30px 0px;
    margin-bottom: 5px;
    width: 100%;
  }

  .main-poster {
    width: 60%;
    height: fit-content;
  }

  .secondary-poster {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
  }

  .posters {
    gap: 5px;
    margin-left: 5px;
    width: 37%;
  }

  .product-type-data h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .product-type {
    flex-direction: column;
  }

  .product-type-data {
    margin-bottom: 0px;
  }

  .product-type img,
  .img-wrapper {
    width: 340px;
    height: 340px;
  }

  .img-wrapper {
    max-width: 340px;
    max-height: 340px;
  }

  .swiper-slide img {
    height: fit-content;
  }

  .discount-image {
    width: 95%;
    margin-bottom: 15px;
  }

  .product-type {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .home-related-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .swiper-slide img {
    object-fit: cover;
  }

  .swiper-slide img {
    height: fit-content;
  }

  .insta-title {
    font-size: 30px !important;
  }

  .product-type img,
  .img-wrapper {
    width: 300px !important;
    height: 300px !important;
  }

  .service p {
    width: 250px;
    font-size: 15px;
  }

  .icon-service {
    font-size: 45px;
  }
}

@media only screen and (max-width: 500px) {
  .swiper-slide img {
    object-fit: contain;
  }

  .swiper-slide img {
    position: static;
    height: auto;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (max-width: 650px) {
  .insta-img {
    width: calc(33.6% - 5px);
  }

  .insta-mapping {
    gap: 5px;
    column-gap: 0px !important;
  }

  .sixth-img {
    display: flex;
  }

  .side-bar-images {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 5px 0px;
    margin-top: 20px;
    gap: 10px;
  }

  .discount-image {
    margin-top: 20px;
  }

  .side-image {
    width: 90%;
    height: fit-content;
  }

  .home-page {
    padding-bottom: 0px;
  }

  .main-poster {
    position: static;
    height: auto;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (max-width: 850px) and (min-width: 770px) {
  .product-type img,
  .img-wrapper {
    width: 220px;
    height: 220px;
  }
}
