.store-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-bottom: 50px;
}

.store-select {
  height: 50px !important;
}

.store-search {
  width: 450px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: sticky;
  top: 43px;
  background-color: white;
}

.store-search-block {
  width: 100%;
  border-bottom: 1px solid black;
  background-color: white;
}

.store-search-block:last-child {
  border-bottom: none;
}

.store-search-item,
.store-search-mapping {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.store-search-mapping {
  padding-bottom: 10px;
}

.store-search-list-item,
.store-search-list-item-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  line-height: 16px;
}

.store-search-list-item-2 {
  justify-content: flex-start !important;
}

.sotre-checbox {
  width: 17px;
  height: 17px;
  margin-right: 5px !important;
  cursor: pointer;
}

.store-wrapper input[type="text"],
.products-header select {
  width: 100%;
  margin-top: 5px;
  border: 1px solid black;
  outline: none;
  padding: 7px 15px;
  font-size: 17px;
  background-color: white !important;
  color: #000 !important;
}

.store-wrapper input[type="text"]:focus {
  border: 2px solid black;
}

.store-search-block button {
  width: 100%;
  background-color: black;
  outline: none;
  border: 2px solid black;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 15px;
  cursor: pointer;
  transition: 285ms ease-in-out;
  font-weight: 700;
  border-radius: 0px;
}

.code-search {
  flex-direction: row;
}

.search-code {
  height: 45px;
  width: calc(100% - 80px);
  border-right: none !important;
}

.search-code-btn {
  height: 45px;
  width: 75px !important;
  margin-top: 5px !important;
}

.empty-shoppinh-cart {
  text-align: center;
}

.store-search-block button:hover {
  background-color: white;
  color: black;
  transition: 285ms ease-in-out;
}

.products-wrapper {
  width: calc(80% - 450px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.products-mapping {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
}

.products-wrapper .product {
  width: calc(33% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  cursor: pointer;
  padding: 10px 0px;
  overflow: hidden;
  transition: 285ms ease-in-out;
  border: 1px solid #fff;
  position: relative;
}

.products-wrapper .product:hover {
  transition: 285ms ease-in-out;
  border: 1px solid #d1d1d1;
}

.products-wrapper .product img {
  width: 80%;
  aspect-ratio: 1/1;
}

.products-wrapper .product h2 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.products-wrapper .product p {
  font-size: 19px;
  font-weight: bolder;
}

.products-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  padding-top: 10px;
  position: sticky;
  top: 42px;
  background-color: white;
  z-index: 1;
}

.flex-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-headline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
}

.open-list {
  max-height: 500px;
  transition: max-height 0.35s cubic-bezier(0.42, 0, 0.3, 1);
}

.close-list {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0.42, 0, 0.3, 1);
}

.select-p {
  transition: 285ms ease-in-out;
  cursor: pointer;
}

/* .select-p:hover {
  transition: 285ms ease-in-out;
  text-decoration: underline !important;
} */

.reset-filters {
  width: 100%;
  text-align: left;
  padding: 5px;
  padding-left: 0px;
  border-bottom: 1px solid black;
  cursor: pointer;
  color: #fa0a32;
}

.reset-filters P {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.new-product {
  position: absolute;
  top: 10px;
  left: 0px;
  padding: 3px 7px;
  background-color: black;
  color: white;
  text-align: center;
}

.new-product span {
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.discounted-product {
  background-color: #fa0a32;
  top: 45px;
  width: 52px;
}

.sort-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sort-content p {
  font-size: 18px;
}

.user-pagination .pagination-block {
  width: 49px;
  height: 49px;
  font-size: 18px;
}

.range-slider .range-slider__thumb, .range-slider .range-slider__range {
  background-color: #000 !important;
  z-index: 1 !important;
}

.price-range-mapping {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.scroll-list-slider {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

@media only screen and (max-width: 1180px) {
  .scroll-list-slider {
    max-height: unset;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 1700px) and (min-width: 1000px) {
  .products-wrapper {
    width: calc(90% - 450px);
  }

  .store-search {
    width: 400px;
  }

  .store-search-mapping {
    padding-bottom: 5px;
  }

  .store-search-list-item,
  .store-search-list-item-2 {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    line-height: 15px;
  }

  .sotre-checbox {
    width: 16px;
    height: 16px;
  }

  .store-wrapper input[type="text"],
  .products-header select {
    padding: 5px 15px;
    font-size: 15px;
  }

  .store-headline,
  .store-search-block {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .products-header {
    padding-bottom: 7px;
    padding-top: 6px;
  }
}

@media only screen and (max-width: 1300px) {
  .store-wrapper .products-wrapper .products-mapping {
    width: 100%;
  }
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
  }
}

@media only screen and (max-width: 1247px) {
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
  }
}

@media only screen and (max-width: 1062px) {
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
  }
}

.filters-mobile {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .store-wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .search-code-btn {
    margin-top: 0px !important;
  }

  .store-search {
    position: initial;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .filters-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 95%;
    border: 2px solid #000;
    background-color: #fff;
    outline: none;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    margin-top: 10px;
  }

  .filter-icon {
    font-size: 20px;
  }

  .store-wrapper .store-search {
    display: none;
  }

  .store-wrapper .products-wrapper {
    width: 100%;
  }
  .store-wrapper input[type="text"], .products-header select 
  {
    margin-top: 0px;
  }
  .store-wrapper .products-wrapper .products-header {
    position: sticky;
    top: 111px;
    width: 95%;
  }
  .store-wrapper .products-wrapper .products-mapping {
    width: 90%;
  }
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
  }
}

@media only screen and (max-width: 925px) {
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
  }
}

@media only screen and (max-width: 800px) {
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
    }

    /* .select-p:hover {
      text-decoration: none !important;
    } */

  .store-headline {
    padding: 5px 0px;
    font-size: 14px;
  }

  .reset-filters P, .store-search-block {
    font-size: 14px;
  }

  .sotre-checbox {
    width: 23px;
    height: 23px;
  }

  .store-wrapper .products-wrapper .products-mapping, .store-wrapper .products-wrapper .products-header  {
    width: 95%;
  }

   .new-product, .discounted-product {
    font-size: 12px;
    padding: 5px 7px;
  }

  .discounted-product {
    top: 35px !important;
  }

  .store-wrapper .products-wrapper .products-mapping {
    gap: 10px;
  }

  .empty-shoppinh-cart p {
    font-size: 15px !important;
  }

  .empty-shoppinh-cart h1 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 555px) {
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
  }
}

@media only screen and (max-width: 550px) {
  .store-wrapper {
    padding-top: 0px;
    padding-bottom: 10px;
  }

  .store-select {
    height: 40px !important;
  }

  
  .store-wrapper .store-search,
  .products-wrapper .products-header,
  .products-wrapper .products-mapping {
    width: 95%;
  }
  .products-header .pagination-block {
    height: 40px;
    width: 40px;
  }
  .user-pagination div.pagination-block  {
    min-width: 40px;
  }

  .products-header .sort-content p {
    font-size: 15px;
  }
  .products-header .sort-content select {
    font-size: 15px;
  }
  .products-mapping .product {
    width: calc(50% - 10px);
    margin: 0px !important;
    padding: 5px;
  }
  .products-mapping .product h2 {
    font-size: 15px;
  }
  .products-mapping .product p {
    font-size: 13px;
  }

  .products-wrapper .product img {
    width: 85%;
  }

  .new-product {
    top: 0px;
  }

  .sort-content p {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .products-header .sort-content select {
    font-size: 14px;
    padding: 7px 10px;
    width: 170px;
  }
}