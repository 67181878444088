.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: white;
}

.active-navbar-link {
  background-color: #fa0a32;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 110px;
  width: 100%;
}

.header-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.navbar .logo-container {
  cursor: pointer;
}
.navbar .logo-container img {
  height: 84px;
  width: 130px;
}
.navbar .nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-products-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #Fff;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  top: -100px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 285ms ease-in-out;
}

.show-search-bar {
  top: 0px;
}

.show-overlay-search {
  background-color: rgba(0, 0, 0, 0.64) !important;
  display: flex !important;
  transition: 285ms ease-in-out;
}

.search-bar-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  display: none;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
  transition: 285ms ease-in-out;
}

.navbar .links a, .link-a, 
.header-button {
  text-decoration: none;
  color: #000;
  transition: 0.25s;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.navbar .links a:hover,
.header-button:hover, .link-a:hover {
  color: #9aa0a7;
}
.search-products-modal .input-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: black;
  width: 500px;
  background-color: #f3f3f3fb;
  padding: 5px;
  border-radius: 30px;
}

.search-btn-header {
  width: 50px;
  height: 50px;
  background-color: #000;
  color: #ffff;
  border: 1px solid #000;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.search-products-modal .nav-items .input-search svg,
.search-products-modal .nav-items .input-search input {
  color: black;
  font-size: 18px !important;
}
.search-products-modal .nav-items .input-search input {
  height: 50px;
  font-size: 14px;
  border: none;
  outline: none;
  padding-left: 10px;
  width: calc(100% - 55px);
 background-color: transparent;
}

.header-phone-main {
  visibility: hidden;
}

.nav-items {
  margin-left: 50px;
  margin-right: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.link-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.link-icon {
  font-size: 25px;
  margin-right: 7px;
}

.navbar-2 {
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  align-self: flex-start;
  top: -1px;
  overflow-y: auto;  
  z-index: 2;
}

.navbar-2 a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: 285ms ease-in-out;
  text-transform: uppercase;
  padding-top: 8px;
  padding-bottom: 8px;
}

.navbar-2 a.active {
  text-decoration: underline;
}

.navbar-2 a:hover {
  opacity: 0.7; 
  transition: 285ms ease-in-out;
}

@media only screen and (max-width: 1300px) {
  .navbar .nav-items .input-search {
    width: 400px;
    padding: 10px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 650px) {
  .navbar-2 a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1180px) and (min-width: 1000px) {
  .navbar-2 a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 350px) {
  .navbar-2 a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1000px) 
{
  .header-phone-main {
    visibility: visible;
  }
}