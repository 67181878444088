.remove-btm-pd {
  padding-bottom: 0px !important;
}

.statistics-by-period {
  width: 95%;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #cfcece;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.period-selection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #cfcece;
  border-radius: 4px;
  overflow: hidden;
}

.period-selection button {
  background-color: transparent;
  border: transparent;
  outline: transparent;
  color: #000;
  padding: 10px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  transition: 285ms ease-in-out;
  cursor: pointer;
}

.period-selection button:hover {
  background-color: #bbb7b7;
  transition: 285ms ease-in-out;
}

.active-period {
  background-color: #fff !important;
}

.period-data-mapping {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #cfcece;
}

.statistics-data-by-peropd {
  width: calc(25% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.period-icon-wrapper {
  width: 90px;
  height: 90px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.period-icon {
  color: #ffff;
  font-size: 35px;
}

.period-data-info {
  height: 90px;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  flex-direction: column;
  margin-left: 10px;
  width: calc(100% - 110px);
  max-width: calc(100% - 110px);
}

.period-data-info h3 {
  font-size: 23px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 5px;
  color: #000;
  word-break: break-all;
  text-overflow: ellipsis;
}

.period-data-info p {
  font-size: 13px;
  line-height: 1;
  color: #000;
  word-break: break-all;
  font-weight: 500;
  text-overflow: ellipsis;
}

.product-and-user-statistics {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  gap: 10px;
}

.dashboard-interactions {
  width: 50%;
  height: 330px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  border: 1px solid #cfcece;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.product-dashboard-mapping {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 5px;
}

.dashboard-product {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.dashboard-left-product {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.dashboard-left-product img {
  width: 55px;
  height: 55px;
}

.dashboard-product-titles {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.dashboard-product-titles h3 {
  width: 100%;
  line-height: 1;
  font-size: 15px;
  font-weight: bold;
  word-break: break-all;
  text-overflow: ellipsis;
}

.dashboard-product:last-child {
  border-bottom: none;
}

.dashboard-product-titles p {
  font-size: 12px;
  color: #d5092b;
  word-break: break-all;
  text-overflow: ellipsis;
  font-weight: bold;
}

.type-mapping {
  width: 125px;
  text-align: center;
  padding: 5px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
}

.chart-wrapper-main {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px !important;
}

.chart-wrapper {
  width: 330px;
  height: 330px;
}

.cloudinary-data {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cloudinary-data-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.cloudinary-data-title h3 {
  width: 210px;
  line-height: 1.2;
}

.windows-logo {
  font-size: 44px;
  color: #bbb7b7;
}

.cloudinary-precentage {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.mini-cloudinary-stat {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.other-cloudinary-stats {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid #cfcece;
}

.cloudinary-plan {
  background-color: #16c784;
  padding: 4px 13px 4px 13px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  border-radius: 3px;
}

.inline-cloudinary {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  gap: 5px;
}

.quota-status {
  width: 120px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  color: #ffff;
  font-weight: normal;
  border-radius: 3px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.cloudinary-data-mapping {
  justify-content: space-between !important;
}

.cloudinary-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  width: fit-content;
}

.period-selection-cloudinary {
  overflow: hidden !important;
}

@media only screen and (max-width: 1600px) and (min-width: 1250px) {
  .cloudinary-data-title {
    gap: 10px;
  }
  
  .cloudinary-data-title h3 {
    width: 170px;
    line-height: 1.2;
    font-size: 15px;
  }
  
  .windows-logo {
    font-size: 34px;
  }

  .cloudinary-precentage h1 {
    font-size: 17px;
  }

  .cloudinary-precentage p {
    font-size: 13px;
  }

  .mini-cloudinary-stat h4 {
    font-size: 14px;
  }

  .mini-cloudinary-stat p {
    font-size: 14px;
  }
  
   .inline-cloudinary h1 {
    font-size: 15px;
   }

  .other-cloudinary-stats {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid #cfcece;
  }
  
  .cloudinary-plan {
    padding: 4px 13px 4px 13px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 3px;
  }
  
  .inline-cloudinary {
    gap: 5px;
  }
  
  .quota-status {
    width: 100px;
    height: 25px;
    line-height: 25px;
    font-size: 15px;
    border-radius: 3px;
  }
}

@media only screen and (max-width: 1050px) {
  .status-data-main {
    height: 100px;
    padding: 10px;
    width: calc(50% - 5px);
  }

  .cloudinary-stats {
    margin-top: 0px;
  }

  .cloudinary-div {
    flex-direction: column !important;
  }

  .cloudinary-data-mapping {
    align-items: flex-start !important;
    flex-direction: column !important;
    gap: 0px !important;
  }

  .cloudinary-data {
    justify-content: space-between !important;
  }

  .cloudinary-data,
  .other-cloudinary-stats,
  .other-cloudinary-stats,
  .cloudinary-data-title,
  .cloudinary-div {
    width: 100%;
    border-left: none;
    margin-left: 0px;
    padding-left: 0px;
  }

  .other-cloudinary-stats,
  .other-cloudinary-stats,
  .cloudinary-data {
    border-bottom: 2px solid #bbb7b7;
  }

  .cloudinary-precentage p {
    font-size: 14px !important;
    width: 120px !important;
  }

  .cloudinary-precentage h1 {
    font-size: 18px !important;
  }

  .cloudinary-data-title h3 {
    width: 180px;
  }

  .cloudinary-data,
  .other-cloudinary-stats,
  .cloudinary-data-title {
    height: 80px;
  }

  .quota-status {
    margin-top: 10px;
    width: 100%;
  }

  .cloudinary-data-title {
    justify-content: flex-start;
  }

  .statistics-data-by-peropd {
    width: calc(50% - 10px);
  }

  .dashboard-interactions {
    width: 100%;
    height: unset;
  }

  .product-and-user-statistics {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .chart-wrapper {
    height: fit-content;
  }
}

@media only screen and (max-width: 1050px) and (min-width: 750px) {
  .chart-wrapper {
    width: 500px;
    height: fit-content;
  }
}

@media only screen and (max-width: 750px) {
  .period-selection {
    max-width: 100% !important;
    overflow: auto !important;
    flex-wrap: nowrap !important;
  }

  .period-selection-cloudinary {
    overflow: hidden !important;
  }

  .period-selection::-webkit-scrollbar-thumb,
  .period-selection::-webkit-scrollbar-track,
  .period-selection::-webkit-scrollbar {
    height: 0px !important;
  }

  .btn-select {
    min-width: fit-content !important;
  }

  .status-data-main {
    height: 75px;
  }

  .status-data-side {
    width: 50px;
    height: 50px;
  }

  .status-data h3 {
    font-size: 23px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
  }

  .status-data p {
    font-size: 14px;
    line-height: 1;
  }

  .status-data-icon,
  .period-icon {
    font-size: 25px;
  }

  .period-icon-wrapper {
    width: 50px;
    height: 50px;
  }

  .period-data-info {
    height: 50px;
    width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }

  .statistics-data-by-peropd {
    justify-content: flex-start;
  }

  .chart-wrapper-main {
    height: auto;
  }

  .chart-wrapper {
    width: 95%;
    height: fit-content;
  }

  .type-mapping {
    width: 100px !important;
    min-width: 100px !important;
    padding: 3px;
    font-size: 11px;
  }

  .dashboard-product-titles h3 {
    font-size: 12px;
  }

  .dashboard-left-product {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 450px) {
  .cloudinary-data-title h3 {
    font-size: 15px;
    width: 130px;
  }

  .cloudinary-data-first {
    padding-top: 0px;
    margin-top: -15px;
  }
}
