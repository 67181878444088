.privacy-and-policy-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 25px;
}

.privacy-text {
  width: 850px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 25px;
  gap: 10px;
}

.privacy-text p {
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
}

.privacy-text ul {
  padding-left: 25px;
}

.privacy-paragraph {
  margin-top: 15px;
}

@media only screen and (max-width: 850px) {
  .privacy-text {
    width: 95%;
  }

  .privacy-text p, .privacy-text b {
    width: 95%;
    max-width: 95%;
  }

  .privacy-and-policy-page {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
