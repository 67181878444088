.burger-menu {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  z-index: 10;
  display: none;
}
.burger-menu .burger-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  background-color: #f3f3f3fb;
  height: 73px;
}
.burger-menu .burger-logo img {
  width: 150px;
}
.burger-menu .burger-items {
  padding: 5px;
}
.burger-menu .burger-items a {
  display: block;
  text-decoration: none;
  color: #000000;
}

.burger-menu .burger-items ul li {
  padding: 5px;
  font-size: 22px;
  font-weight: 600;
  list-style-type: none;
  margin: 0px !important;
}
.burger-active-link {
  display: block;
  background: #000000;
  width: 100%;
  padding: 10px;
  color: #ffffff !important;
  border-radius: 8px;
}
.burger-unactive-link {
  color: #000000 !important;
}
.header-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px;
  background-color: white;
  height: 73px;
  border-bottom: 1px solid #d1d1d1;
}

.burger-menu-links {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  overflow-y: auto;
  padding-bottom: 60px;
}

.burger-menu-plain-link {
  width: 100%;
  padding: 10px 5px;
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #000;
  font-size: 15px;
}

.plain-icon {
  font-size: 25px;
}

.discount-image-burger {
  width: 95%;
  margin-top: 10px;
}

.burger-link {
  width: 95%;
  padding: 5px 5px;
  padding-left: 10px;
  background-color: #e4e3e3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.cart-and-account {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  position: fixed;
  bottom: 0px;
}

.burger-btn {
  width: 50%;
  border: none;
  outline: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  font-weight: bold;
  height: 55px;
}

.cart-burger-btn {
  background-color: #fa0a32;
}

.acc-burger-btn {
  background-color: #000;
}

.burger-link-img {
  width: 60px;
  height: 60px;
}

.header-main-menu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 2;
}

.header-phone img {
  width: 65px;
  margin-left: 18px;
}

.header-icon-block {
  height: 73px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-products-count-mobile {
  background-color: #fa0a32;
  padding: 5px;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  font-size: 10px;
  line-height: 10px;
  margin-top: -25px;
  margin-right: -35px;
}

.header-block-width {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-mobile-input {
  width: calc(100% - 50px);
  border: 1px solid black;
  outline: 1px solid #fff;
  font-size: 17px;
  padding: 7px;
  height: 50px;
}

.search-btn-mobile {
  background-color: #000000;
  color: #fff;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open-burger-anim {
  animation: openBurger 0.25s forwards;
}

.close-burger-anim {
  animation: closeBurger 0.25s forwards;
}

@keyframes openBurger {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes closeBurger {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}

.align-header-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}