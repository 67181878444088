.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.not-found p {
  font-size: 22px;
}

.not-found * {
  margin: 10px 0;
}

.not-found h1 {
  font-size: 200px;
  color: #FA0A32;
}

.not-found button {
  display: block;
  margin: 20px auto;
  height: 60px;
  width: 250px;
  background: #000000;
  border: 2px solid black;
  outline: navajowhite;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  transition: 285ms ease-in-out;
  font-weight: bold;
}

.not-found button:hover {
  background-color: white;
  color: black;
  transition: 285ms ease-in-out;
}

.not-found button span {
  margin-left: 3px;
}
