.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: #eaeaee;
  padding-top: 35px;
}
.footer h3 {
  font-weight: 500;
  font-size: 17px;
  text-align: center;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.newsletter-form h3 {
  font-size: 30px;
  font-weight: 600;
}

.newsletter-form p {
  width: 500px;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}

.newsletter-form input {
  width: 450px;
  border: 1px solid #000;
  outline: none;
  font-size: 18px;
  height: 50px;
  padding-left: 8px;
}

.newsletter-form input:focus {
  border: 2px solid black;
}

.lets-socialize {
  font-size: 20px !important;
  margin-bottom: 8px !important;
  font-weight: bold !important;
}

.newsletter-form button {
  width: 135px;
  background-color: black;
  outline: none;
  border: 2px solid black;
  color: white;
  height: 50px;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 285ms ease-in-out;
  font-weight: 700;
}

.submit-newsletter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
}

.newsletter-form button:hover {
  background-color: white;
  color: black;
  transition: 285ms ease-in-out;
}

.newsletter {
  width: 100%;
  /* border-bottom: 2px solid #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: #f6f6f8;
}

.copyright {
  width: 65%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid black;
  font-style: italic;
  z-index: 2;
}

.sitemap {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
}

.sitemap-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: black;
}

.sitemap-block h3 {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.sitemap-block a,
.sitemap-block p {
  font-size: 16px;
  text-decoration: none;
  color: black;
  line-height: 26px;
  font-weight: 500;
}

.location-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 8px;
}

.location-data:nth-child(0) {
  margin-top: 0px;
}

.location-data p {
  margin-top: -5px;
}

.location-data span {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-sitemap img {
  width: 150px;
  height: 100px;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.top-border-media {
  border-top: 1px solid #000;
  width: 150px;
  padding-top: 10px;
}

.social-media-icon {
  display: block;
  color: #fff;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
  transition: 285ms ease-in-out;
  border: 2px solid #000;
}

.newsletter-error {
  width: 450px;
}

.social-media-icon:hover {
  background-color: #eaeaee;
  color: #000;
  transition: 285ms ease-in-out;
}

.note-for-copyrights {
  font-size: 13px;
  max-width: 60%;
  z-index: 2;
}

.supported-payment-cards {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.supported-payment-cards img {
  width: 75px;
  height: 45px;
  border-radius: 5px;
}

@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  .sitemap,
  .copyright  {
    width: 85%;
  }

  .note-for-copyrights {
    max-width: 70%;
  }

  .logo-sitemap img {
    width: 120px;
    height: 90px;
  }
}

@media only screen and (max-width: 1200px) {
  .sitemap {
    width: 90%;
  }

  .logo-sitemap img {
    display: none;
  }

  .copyright  {
    width: 90%;
  }

  .note-for-copyrights {
    max-width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .sitemap .sitemap-block {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .note-for-copyrights {
    font-size: 11px;
  }

  .sitemap-block h3 {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .sitemap-block a,
  .sitemap-block p {
    font-size: 15px;
    margin-block: 2px;
  }

  .sitemap {
    width: 90%;
    justify-content: center;
    align-items: center;
    z-index: 1;
    flex-direction: column;
  }

  .sitemap .store-search-block {
    background-color: #eaeaee !important;
    border-bottom: 1px solid #000 !important;
  }

  .sitemap .store-headline h3 {
    font-weight: bolder !important;
  }

  .social-media-block {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
  }

  .social-media-block h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .newsletter-form h3 {
    font-size: 20px;
  }

  .newsletter-error{
    width: 90%;
  }
  
  .newsletter-form p {
    width: 90%;
    font-size: 14px;
    line-height: 18px;
  }
  
  .newsletter-form input {
    width: calc(94% - 95px);
    font-size: 14px;
    height: 45px;
    padding-left: 8px;
  }
  
  .submit-newsletter {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .newsletter-form button {
    width: 95px;
    height: 45px;
    font-size: 13px;
  }
}