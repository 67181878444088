.product-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 0px;
}

.product-swiper {
  display: none;
}

.product-wrapper .data-wrapper {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.product-wrapper .data-wrapper .images-list {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.product-wrapper .data-wrapper .product-images {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.product-wrapper .data-wrapper .images-list .mapped-image {
  width: 110px;
  height: fit-content;
  cursor: pointer;
  margin-bottom: 3px;
  transition: 285ms ease-in-out;
  border: 1px solid #fff;
}

.product-wrapper .data-wrapper .images-list .mapped-image:hover {
  border: 1px solid #d1d1d1;
  transition: 285ms ease-in-out;
}

.product-wrapper .data-wrapper .main-image {
  width: calc(80% - 120px);
  height: fit-content;
  margin-left: 70px;
  margin-right: 7px;
  cursor: pointer;
}

.product-wrapper .data-wrapper .images-list .selected-image {
  border: 1px solid #d1d1d1;
}

.product-wrapper .data-wrapper .product-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 40%;
}

.product-wrapper .data-wrapper .product-data .title {
  font-weight: bold;
}

.save-to-wishlist {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
}

.product-internal-data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.new-product-block {
  padding: 4px 12px;
  background-color: black;
  color: white;
  text-align: center;
  margin: 14px 0px;
}

.new-product-block span {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.size-mapping {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
}

.size {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d1d1;
  margin: 5px;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.size:hover {
  transition: 285ms ease-in-out;
  background-color: #f3f3f3fb;
}

.selected-size {
  background-color: #f3f3f3fb;
}

.unselected-size {
  background-color: #fff;
}

.size-mapping .size span {
  font-size: 17px;
  line-height: 17px;
}

.product-wrapper .data-wrapper .price {
  font-weight: bolder;
}

.specifications {
  width: 100%;
  margin-top: 5px;
}

.title-marg {
  margin-top: 20px;
}

.product-data table,
.product-data tr,
.product-data td,
.product-data th {
  border: none;
  border-collapse: collapse;
  font-size: 17px;
  padding: 4px 3px;
}

.product-data td {
  width: 50%;
}

.product-data tr:nth-child(odd) {
  background-color: #f3f3f3fb;
}

.product-data tr:nth-child(even) {
  background-color: #ffffff;
}

.cart-adder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.cart-options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-options button,
.cart-options div {
  width: 55px;
  height: 55px;
  border: 1px solid #d1d1d1;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.cart-options div {
  border-left: none;
  border-right: navajowhite;
}

.cart-options button {
  font-size: 22px;
  font-weight: bold;
  background-color: #f3f3f3fb;
  color: black;
  cursor: pointer;
}

.cart-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 7px;
}

.add-to-cart {
  background-color: #000;
  border: 2px solid #000;
  height: 53px;
  font-size: 15px;
  padding: 0px 15px;
  color: white;
  font-weight: bold;
  width: 250px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.product-button-options {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.save-product-btn,
.delete-all-saved {
  height: 53px;
  width: 53px;
  font-size: 32px;
  color: white;
  background-color: #fa0a32;
  border: 2px solid #fa0a32;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.delete-all-saved {
  font-size: 20px;
  font-weight: bold;
  width: 200px;
}

.delete-all-saved:hover {
  color: #fa0a32;
  background-color: #fff;
  transition: 285ms ease-in-out;
}

.wishlist-products,
.wishlist-header {
  width: 80% !important;
}

.wishlist-header {
  top: 42px !important;
}

.wishlist-products {
  padding-top: 15px;
}

.add-to-cart:hover {
  color: #000;
  background-color: #fff;
  transition: 285ms ease-in-out;
}

.save-product-btn:hover {
  font-size: 38px;
  transition: 285ms ease-in-out;
}

.add-to-cart-icon {
  font-size: 31px;
  margin-right: 5px;
}

.recently-viewd {
  margin-top: 70px !important;
}

.related-wrapper {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 25px;
}

.related-title {
  font-size: 24px;
}

.related-products-mapping {
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.related-products-mapping .product {
  width: calc(20% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px;
  cursor: pointer;
  padding: 10px 0px;
  overflow: hidden;
  transition: 285ms ease-in-out;
  border: 1px solid #fff;
  position: relative;
}

.related-products-mapping .product:hover {
  transition: 285ms ease-in-out;
  border: 1px solid #d1d1d1;
}

.related-products-mapping .product img {
  width: 70%;
  aspect-ratio: 1/1;
}

.product img {
  width: 70%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.related-products-mapping .product h2 {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.related-products-mapping .product-item-title-wrp {
  min-height: 36px !important;
}

.related-products-mapping .product p {
  font-size: 18px;
  font-weight: bold;
}

.tags-for-product {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.tags-for-product .discounted-product {
  position: unset;
  background-color: #fa0a32;
  width: 65px;
}

.discounted-price {
  margin-right: 5px;
  font-size: 15px !important;
  opacity: 0.7;
}

.prices-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px 0px;
}

.prices-list h3 {
  opacity: 0.7;
  font-size: 20px;
}

.product-img-mobile {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  height: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}

.product-item-title-wrp {
  width: 100%;
  display: flex;
  min-height: 36px !important;
  justify-content: center;
  align-items: flex-start;
}

.product-item-price-wrp {
  width: 100%;
  display: flex;
  min-height: 40px !important;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px !important;
}

.product p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
}

/* ELEMENT DIMENSIONS */
@media only screen and (max-width: 1650px) and (min-width: 980px) {
  .related-wrapper {
    margin-top: 25px;
  }

  .product-wrapper {
    padding: 15px 0px;
  }

  .title-marg {
    margin-top: 10px;
  }

  .size,
  .cart-options button,
  .cart-options div {
    width: 50px;
    height: 50px;
  }

  .add-to-cart {
    height: 50px;
    font-size: 14px;
    padding: 0px 15px;
    width: 250px;
    margin-left: 20px;
  }

  .save-product-btn {
    width: 50px;
    height: 50px;
  }

  .new-product-block {
    margin: 10px 0px;
  }
}

/* ELEMENT DIMENSIONS */
@media only screen and (max-width: 980px) {
  .related-wrapper {
    margin-top: 20px;
  }

  .recently-viewd {
    margin-top: 45px !important;
    margin-bottom: 15px !important;
  }

  .related-wrapper {
    align-items: center;
  }

  .product-wrapper {
    padding: 15px 0px;
  }

  .title-marg {
    margin-top: 10px;
  }

  .size,
  .cart-options button,
  .cart-options div {
    width: 55px;
    height: 55px;
  }

  .size,
  .cart-options button {
    font-size: 27px;
  }

  .add-to-cart {
    height: 55px;
    font-size: 14px;
    padding: 0px 15px;
    width: 250px;
    margin-left: 20px;
  }

  .save-product-btn {
    width: 55px;
    height: 55px;
  }

  .new-product-block {
    margin: 10px 0px;
  }

  .product-swiper {
    display: flex;
    width: 100%;
  }

  .product-swiper .swiper-button-prev,
  .product-swiper .swiper-button-next {
    display: none;
  }

  .product-swiper img {
    height: unset;
  }

  .product-swiper .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    border-radius: 0%;
  }
}

/* WRAPPERS WIDTH */
@media only screen and (max-width: 1650px) and (min-width: 1180px) {
  .product-wrapper .data-wrapper,
  .related-wrapper {
    width: 90%;
  }

  .wishlist-products,
  .wishlist-header {
    width: 90% !important;
  }

  .product-wrapper .data-wrapper .main-image {
    width: calc(82% - 100px);
    margin-left: 30px;
    margin-right: 30px;
  }

  .product-wrapper .data-wrapper .product-data {
    width: 42%;
  }
}

/* WRAPPERS WIDTH */
@media only screen and (max-width: 1180px) and (min-width: 980px) {
  .product-wrapper .data-wrapper,
  .related-wrapper {
    width: 95%;
  }

  .wishlist-products,
  .wishlist-header {
    width: 100% !important;
  }

  .product-wrapper .data-wrapper .main-image {
    width: calc(82% - 100px);
    margin-left: 30px;
    margin-right: 30px;
  }

  .product-wrapper .data-wrapper .product-data {
    width: 43%;
  }

  .product-wrapper .data-wrapper .images-list .mapped-image {
    width: 90px;
  }

  .related-products-mapping .product {
    width: calc(25% - 10px);
  }
}

/* WRAPPERS WIDTH */
@media only screen and (max-width: 980px) and (min-width: 650px) {
  .product-swiper-img {
    max-height: 550px !important;
  }
  .wishlist-products,
  .wishlist-header {
    width: 100% !important;
  }
}

/* WRAPPERS WIDTH */
@media only screen and (max-width: 980px) and (min-width: 500px) {
  .product-wrapper .data-wrapper,
  .related-wrapper {
    width: 95%;
  }

  .wishlist-products,
  .wishlist-header {
    width: 100% !important;
  }

  .product-wrapper .data-wrapper,
  .related-wrapper {
    flex-direction: column;
  }

  .product-wrapper .data-wrapper .product-images {
    width: 100%;
    flex-direction: column-reverse;
  }

  .product-wrapper .data-wrapper .images-list,
  .product-wrapper .data-wrapper .main-image {
    display: none;
  }

  .product-swiper-img {
    width: 100%;
    max-height: 400px;
    margin: auto;
    object-fit: cover;
  }

  .product-wrapper .data-wrapper .product-data {
    width: 100%;
  }

  .related-products-mapping .product {
    width: calc(50% - 10px);
  }

  .product-wrapper {
    padding: 0px !important;
  }

  .related-products-mapping .product h2 {
    font-size: 19px;
    line-height: 23px;
    height: 46px;
  }

  .related-products-mapping .product p {
    font-size: 17.5px;
    margin-top: 5px;
  }
}

/* WRAPPERS WIDTH */
@media only screen and (max-width: 500px) {
  .product-wrapper .data-wrapper,
  .related-wrapper {
    width: 95%;
  }

  .wishlist-products,
  .wishlist-header {
    width: 100% !important;
  }

  .product-wrapper .data-wrapper {
    margin-bottom: 0px;
  }

  .related-wrapper .related-title {
    margin-bottom: 20px;
  }

  .related-products-mapping .product {
    margin: 0px;
  }

  .product-wrapper .data-wrapper,
  .related-wrapper {
    flex-direction: column;
  }

  .product h2 {
    font-size: 15px !important;
    line-height: 23px;
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
  }

  .product img {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
  }

  .product {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .new-product-mapping-mobile span {
    font-size: 12px !important;
  }

  .discounted-product {
    top: 25px !important;
  }

  .product-item-title-wrp {
    width: 100%;
    display: flex;
    min-height: 46px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .product-item-price-wrp {
    width: 100%;
    display: flex;
    min-height: 46px !important;
    margin: 0px !important;
  }

  .new-product-mapping-mobile {
    padding: 3px 5px !important;
    width: 45px !important;
    left: unset !important;
    right: 0px !important;
  }

  .save-to-wishlist {
    left: 10px;
    top: 0px;
  }

  .product:hover {
    border-color: transparent !important;
  }

  .product p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column-reverse;
    margin: 0px !important;
    padding: 0px !important;
    font-size: 16px !important;
  }

  .product s {
    font-size: 13px !important;
  }

  .product-wrapper .data-wrapper .product-images {
    width: 100%;
    flex-direction: column-reverse;
  }

  .product-wrapper .data-wrapper .images-list,
  .product-wrapper .data-wrapper .main-image {
    display: none;
  }

  .product-swiper-img {
    width: 100%;
    max-height: 400px;
    margin: auto;
    object-fit: cover;
  }

  .product-wrapper .data-wrapper .product-data {
    width: 100%;
  }

  .related-products-mapping {
    gap: 10px;
  }

  .related-products-mapping .product {
    width: calc(50% - 10px);
  }

  .product-wrapper {
    padding: 0px !important;
  }

  .related-products-mapping .product h2 {
    font-size: 16px;
    line-height: 19px;
    height: 46px;
  }

  .related-products-mapping .product p {
    font-size: 14.5px;
    margin-top: 5px;
  }

  .cart-block {
    flex-direction: column;
  }

  .product-button-options {
    margin-top: 15px;
    width: 100%;
  }

  .add-to-cart {
    width: calc(100% - 60px);
    height: 50px;
    margin-left: 0px;
  }

  .save-product-btn {
    width: 50px;
    height: 50px;
  }

  .related-products-mapping .product h2 {
    height: unset;
  }

  .product-wrapper .data-wrapper .product-data .title,
  .product-wrapper .data-wrapper .price {
    font-size: 28px;
  }
}

@media only screen and (max-width: 800px) {
  .product-data-blck {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}
