@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

html {
	-webkit-tap-highlight-color: transparent
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar ::-webkit-scrollbar {
  width: 0px !important;
}

#root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

select {
  background-color: #fff !important;
}

.modal-error {
  display: none;
  color: #fa0a32;
  margin-top: 2px;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 1000% !important;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 285ms ease-in-out;
  z-index: 91;
}

.modal-content {
  min-width: 450px;
  padding: 10px 0px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 92;
  transition: 285ms ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-content {
  z-index: 92;
  transition: 285ms ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 95%;
  height: 98vh;
}

.modal-close-image {
  position: fixed;
  top: 10px;
  right: 10px;
  color: #fff;
  z-index: 99;
  font-size: 35px;
  cursor: pointer;
}

.modal-headline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  padding-top: 5px;
  border-bottom: 1px solid #d1d1d1;
}

.modal-headline h1 {
  font-size: 20px;
}

.modal-close {
  font-size: 30px;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.modal-close:hover {
  color: #fa0a32;
  transition: 285ms ease-in-out;
}

.closed-modal {
  opacity: 0;
  transition: 250ms ease-in-out;
}

.open-modal {
  opacity: 1;
  animation: 285ms fadeIn;
}

.hidden-modal {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-data-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;
}

.modal-data-list .content-wrapper {
  width: 90%;
}

.modal-background .modal-box .input-element label {
  font-size: 19px;
}

.modal-background .modal-box .input-element {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 16px;
}

.modal-background .modal-box .input-element input {
  width: 90%;
  height: 50px;
  margin-top: 5px;
  border: 1px solid black;
  outline: none;
  padding: 15px;
  font-size: 17px;
}

.modal-data-list .input-element label {
  margin-bottom: -2px;
  font-size: 17px;
}

.modal-data-list .input-element {
  margin-top: 9px;
}

.modal-background .modal-box .input-element input:focus {
  border: 2px solid black;
}

.modal-data-list button {
  width: 90%;
  background-color: black;
  outline: none;
  border: 2px solid black;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 17px;
  text-transform: uppercase;
  margin-top: 25px;
  cursor: pointer;
  transition: 285ms ease-in-out;
  font-weight: 700;
  border-radius: 7px;
}

.modal-data-list button:hover {
  background-color: white;
  color: black;
  transition: 285ms ease-in-out;
}

input[type="checkbox"] {
  accent-color: #000;
}

.scroll-to-top-btn {
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: black;
  color: #ffff;
  font-size: 25px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 9;
  animation: fadeIn 285ms ease-in-out;
  transition: 285ms ease-in-out;
  border: 2px solid #000;
}

.scroll-to-top-btn:hover {
  background-color: white;
  color: black;
  transform: scale(1.15);
  transition: 285ms ease-in-out;
}

.min-height-block {
  min-height: 450px;
}

.full-width {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.success {
  background-color: #16c784;
  border-right: 6px solid #0d9661;
}

.failed {
  background-color: #fa0a32;
  border-right: 6px solid #b40623;
}

.notification {
  width: 100%;
  min-height: 59px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  margin-top: 9px;
}

.notification p {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  line-height: 22px;
}

@media only screen and (max-width: 480px) {
  .modal-content {
    min-width: 95%;
    max-width: 95%;
    width: 95%;
  }
}

@media only screen and (max-width: 985px) {
  .image-content {
    width: 95%;
    max-height: 80vh;
    height: fit-content;
  }  

  .order-button:hover, .button-el-classic:hover, .search-code-btn:hover, .add-to-cart:hover, .go-to-checkout:hover, .checkout-btn:hover,
  .remove-hover-black:hover, .sign-in-page .form button:hover, .button-el-classic:hover, .social-media-icon:hover, .submit-newsletter button:hover
  {
    background-color: #000 !important;
    color: #ffff !important;
  }
  

  .edit-order-btn:hover, .delete-all-saved:hover, .delete-btn:hover, .sign-out:hover {
    color: #ffff !important;
    background-color: #fa0a32 !important;
  }

  .change-password:hover, .account-edit:hover {
    color: #fff !important;
    background-color: #0064e4 !important;
  }
}