.checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  width: 100%;
}

.checkout-form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #cfcece;
  padding: 30px;
}

.checkout label {
  margin: 3px 0;
}
.checkout .user-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.checkout .user-data input {
  padding: 15px;
  font-size: 18px;
  height: 50px;
  outline: none;
}
.checkout .user-data .name-and-lastname {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.checkout .user-data .name-and-lastname div,
.checkout .user-data .country,
.checkout .user-data .address {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 10px;
}

.checkout .user-data .country,
.checkout .user-data .address,
.checkout .user-data .city,
.checkout .user-data .postal-code,
.checkout .user-data .phone,
.checkout .user-data .email,
.checkout .user-data .note,
.checkout .user-data .company {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px 0;
}
.checkout .user-data .country input,
.checkout .user-data .address input,
.checkout .user-data .city input,
.checkout .user-data .postal-code input,
.checkout .user-data .phone input,
.checkout .user-data .email input {
  width: 280px;
}

.checkout .user-data input:focus {
  border: 2px solid black;
}

.checkout .user-data .company input {
  width: 550px;
}

.wrap-checkout-elements {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.note {
  width: 570px;
}

.note textarea {
  resize: vertical;
  max-height: 300px;
  padding: 7px;
  font-size: 18px;
}

.note h3 {
  margin-bottom: -10px;
}

.checkout-btn {
  width: 100%;
  background-color: black;
  outline: none;
  border: 2px solid black;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 19px;
  text-transform: uppercase;
  margin-top: 19px;
  cursor: pointer;
  transition: 285ms ease-in-out;
  font-weight: 700;
  border-radius: 5px;
}

.checkout-btn:hover {
  background-color: white;
  color: black;
  transition: 285ms ease-in-out;
}

.user-data sup {
  color: #fa0a32;
}

.user-data .headline {
  width: 570px;
  text-align: left;
  font-size: 20px;
}

.payment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 9px;
  font-weight: bold;
}

.checkout-product {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cfcece; 
  margin-bottom: 5px;
  padding-left: 5px;
}

.freez-btn {
  cursor: auto !important;
  background-color: #888888 !important;
  border-color: #888888 !important;
}

.freez-btn:hover {
  color: #fff !important;
  cursor: auto !important;
  background-color: #888888 !important;
  border-color: #888888 !important;
}

/* .error-width-checkout .notification{
  width: 55% !important;
  max-width: 55% !important;
  background-color: #5e5e5e !important;
} */

.cart-mapping {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.checkout-product-image {
  width: 160px;
  height: fit-content;
  cursor: pointer;
}

.main-checkout-details {
  margin-left: 25px;
}

.checkout-title {
  width: 210px;
  max-width: 210px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  margin-bottom: 7px;
}

.new-product-block-2 {
  padding: 5px 0px;
  width: 60px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  background-color: black;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkout-code {
  color: #5e5e5e;
  font-weight: bold;
  font-size: 15px;
}

.flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.checkout-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 395px;
}

.data-checkout-div {
  text-align: center;
}

.data-checkout-td {
  text-align: center;
  width: 150px;
}

.data-checkout-div h1 {
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle !important;
}

.remove-btn {
  border: 1px solid #d1d1d1;
  width: 95px;
  height: 50px;
  font-size: 17px;
  outline: none;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
}

.reduced-width {
  width: 100px;
}

.order-product-info {
  margin-left: 30px;
  width: calc(100% - 440px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-block-2 {
  display: inline-block;
  margin-bottom: 0px;
}

.table-size {
  border: 1px solid #cfcece;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  margin-bottom: 0px;
}

.cart-options-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-options-2 button,
.cart-options-2 div {
  width: 50px;
  height: 50px;
  border: 1px solid #d1d1d1;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.cart-options-2 div {
  border-left: none;
  border-right: navajowhite;
}

.cart-options-2 button {
  font-size: 22px;
  font-weight: bold;
  background-color: #f3f3f3fb;
  color: black;
  cursor: pointer;
}

.input-data-wrapper {
  width: 1180px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.promo-form {
  width: 530px;
  padding: 30px;
  border: 1px solid #cfcece;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 15px;
}

.payment-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #cfcece;
  margin-top: 10px;
  padding-top: 10px;
}

.payment-detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px 10px;
}

.privacy-policy {
  width: 100%;
  font-size: 15px;
  margin-top: 25.5px;
}

.privacy-policy a {
  color: #0064e4;
  margin-left: 4px;
  font-weight: bold;
}

.article-list {
  width: 100%;
  padding: 10px;
  border-bottom: 4px solid #cfcece;
}

.checkout-discount {
  background-color: #fa0a32;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 60px;
  height: 30px;
  margin-bottom: 7px;
}

.regular-price {
  font-size: 14px;
  opacity: 0.7;
}

.cart-list-data {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 5px;
  padding-bottom: 25px;
}

.go-to-checkout {
  padding: 12px 15px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  background-color: #000;
  border: 2px solid #000;
  cursor: pointer;
  transition: 285ms ease-in-out;
}

.go-to-checkout:hover {
  background-color: white;
  color: black;
  transition: 285ms ease-in-out;
}

.cart-total-amount {
  width: 100%;
  padding: 15px;
  background-color: #f3f3f3fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.empty-shoppinh-cart {
  padding: 50px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.empty-icon {
  font-size: 200px;
}

.empty-icon path[d='m14 13.99 4-5h-3v-4h-2v4h-3l4 5z'] {
  color: #fa0a32;
}

.empty-shoppinh-cart h1 {
  font-size: 27px;
}

.empty-shoppinh-cart p {
  font-size: 20px;
  margin: 20px 0px;
  max-width: 500px;
  opacity: 0.7;
  text-align: center;
}

.order-confirmation-popup {
  width: 550px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.order-confirmation-popup h1 {
  max-width: 100%;
  text-align: left;
  font-size: 20px;
}

.order-confirmation-popup table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 25px;
  font-size: 17px;
}

.order-confirmation-popup td {
  padding: 6px;
  border-collapse: collapse;
  border-bottom: 1px solid #f3f3f3fb;
}

.order-confirmation-popup tr:nth-child(odd) {
  background-color: #f3f3f3fb;
}

.cart-arrow-start {
  margin-left: 7px;
  padding: 0;
  margin-top: 0;
  margin-bottom: -4px;
  font-size: 20px;
}

.continue-checkout {
  margin-left: 7px;
  padding: 0;
  margin-top: 0;
  margin-bottom: -4px;
  font-size: 20px;
}

.cart-article-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #cfcece;
}

.block-data-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-image-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
}

.cart-image-wrapper .checkout-title {
  width: 155px;
}

.cart-fncs-mobile {
  width: calc(100% - 345px);
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 25px;
}

.cart-image-wrapper img {
  width: 140px;
  height: 140px;
}

.order-form .input-element {
  margin-top: 5px;
}

.cart-bottom-data {
  background-color: rgb(248, 248, 248);
  padding: 10px;
}

.tablet-view {
  display: block;
}

.mobile-view {
  display: none;
}

.cart-article-mobile .main-checkout-details {
  padding-top: 10px;
}

.completed-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 0px;
  text-align: center;
}

.faded-note {
  color: #5e5e5e;
  font-size: 13px;
  margin-top: 3px;
}

.data-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-model {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;
}

.info-model h1 {
  font-size: 20px;
}

.info-model h3 {
  margin-bottom: 7px;
  font-size: 18px;
}

.info-model h3.regular-price {
  margin-bottom: 0px;
}

.order-product-info-sizes-middle {
  display: none;
}

@media only screen and (max-width: 1600px) and (min-width: 1200px) {
  .order-item-width-admin {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .data-checkout-td {
    width: 130px;
  }

  .data-checkout-div h1 {
    font-size: 16px;
  }

  .remove-btn {
    width: 75px;
    height: 40px;
    font-size: 15px;
  }

  .reduced-width {
    width: 100px;
  }

  .checkout-data-list {
    margin-left: 30px;
    width: calc(100% - 380px);
  }

  .checkout-main {
    width: 355px;
  }

  .cart-mapping .cart-options-2 button,
  .cart-mapping .cart-options-2 div,
  .cart-mapping .table-size {
    width: 45px;
    height: 45px;
  }

  .checkout-code {
    font-size: 14px;
  }

  .checkout-product-image {
    width: 130px;
  }

  .cart-mapping .checkout-discount {
    font-size: 14px;
    width: 50px;
    height: 25px;
    margin-bottom: 5px;
  }

  .cart-mapping {
    width: 1200px;
    padding: 10px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 950px) {
  .data-checkout-td {
    width: 130px;
  }

  .data-checkout-div h1 {
    font-size: 16px;
  }

  .remove-btn {
    width: 75px;
    height: 40px;
    font-size: 15px;
  }

  .reduced-width {
    width: 100px;
  }

  .order-product-info {
    width: calc(100% - 380px);
  }

  .checkout-main {
    width: 355px;
  }

  .cart-mapping .cart-options-2 button,
  .cart-mapping .cart-options-2 div,
  .cart-mapping .table-size {
    width: 45px;
    height: 45px;
  }

  .checkout-code {
    font-size: 14px;
  }

  .checkout-product-image {
    width: 130px;
  }

  .cart-mapping .checkout-discount {
    font-size: 14px;
    width: 50px;
    height: 25px;
    margin-bottom: 5px;
  }

  .cart-mapping {
    width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .input-data-wrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .wrap-checkout-elements .input-element {
    margin-top: 0px;
  }

  .order-item-width-admin {
    width: 100%;
  }

  .form-sides {
    width: 100%;
  }

  .input-data-wrapper .checkout-form {
    width: 80%;
    padding: 30px 0;
  }
  .input-data-wrapper .user-data {
    width: 100%;
  }
  .input-data-wrapper .user-data .headline {
    width: auto;
  }
  .input-data-wrapper .user-data .wrap-checkout-elements {
    justify-content: center;
    flex-direction: column;
    width: 95%;
  }
  .input-data-wrapper .checkout-btn {
    width: 95%;
    margin: 19px auto 0 auto;
  }
  .wrap-checkout-elements div {
    width: 100%;
  }
  .wrap-checkout-elements div input {
    width: 100% !important;
  }
  .input-data-wrapper .user-data .note {
    width: 95%;
  }
  .input-data-wrapper .promo-form {
    width: 80%;
    margin: 15px auto 15px auto;
  }
  .cart-mapping .checkout-product {
    width: 95% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .input-data-wrapper .checkout-form {
    width: 95%;
  }
  .input-data-wrapper .promo-form {
    width: 95%;
  }
}

@media only screen and (max-width: 950px) {
  .data-checkout-div h1 {
    font-size: 16px;
  }

  .checkout-form {
    margin-bottom: 25px !important;
  }

  .order-item-width-admin {
    width: 100% !important;
    flex-direction: column !important;
  }

  .checkout-main {
    width: 100%;
    justify-content: flex-start;
  }

  .order-product-info {
    width: 100%;
    padding: 10px 0px;
    background-color: #f7f7f7;
    margin-left: 0px;
    margin-top: 5px;
  }

  .order-product-info h3 {
    font-size: 15px;
  }

  .order-product-info h1 {
    font-size: 18px;
  }

  .remove-btn {
    width: 75px;
    height: 40px;
    font-size: 15px;
  }

  .reduced-width {
    width: 100px;
  }

  .cart-mapping .cart-options-2 button,
  .cart-mapping .cart-options-2 div,
  .cart-mapping .table-size {
    width: 45px;
    height: 45px;
  }

  .checkout-code {
    font-size: 14px;
  }

  .checkout-product-image,
  .no-product-image {
    width: 130px;
  }

  .cart-mapping .checkout-discount {
    font-size: 14px;
    width: 50px;
    height: 25px;
    margin-bottom: 5px;
  }

  .cart-mapping {
    width: 100%;
    padding: 10px;
  }

  .orders-map {
    padding-bottom: 0px !important;
  }
}

@media only screen and (max-width: 700px) {
  .tablet-view {
    display: none;
  }

  .mobile-view {
    display: flex;
  }

  .data-block h1 {
    font-size: 18px;
  }

  .data-block h3 {
    font-size: 15px;
  }

  .cart-image-wrapper img {
    width: 100px;
    height: 100px;
  }

  .mobile-padding {
    padding: 10px 0px;
    border-top: 1px solid #f0f0f0;
  }

  .cart-image-wrapper {
    width: 100%;
  }

  .cart-article-mobile .main-checkout-details {
    width: calc(100% - 120px);
  }

  .cart-image-wrapper .checkout-title {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    line-height: 20px;
  }

  .padding-bottom-mobile {
    padding-bottom: 10px;
  }

  .cart-total-amount h1,
  .article-list h1 {
    font-size: 22px;
  }

  .cart-total-amount p {
    font-size: 14px;
  }

  .go-to-checkout {
    font-size: 15px;
    padding: 10px 12px;
  }

  .cart-total-amount {
    background-color: #dddddd;
  }

  .input-data-wrapper .promo-form {
    border: none;
    padding: 0px 5px;
  }

  .checkout-form {
    border: none;
    padding: 5px 0px !important;
  }

  .wrap-checkout-elements {
    gap: 0px;
  }

  .checkout {
    padding: 5px 0px;
  }

  .order-confirmation-popup {
    width: 100%;
  }
}

@media only screen and (max-width: 380px) {
  .cart-article-mobile .main-checkout-details {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .info-model {
    width: calc(50% - 20px);
  }

  .order-product-info-sizes-middle {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid #e4e4e4;
    padding: 5px 0px;
  }

  .order-product-info-sizes-middle h3 {
    font-size: 15px;
  }

  .order-product-info-sizes-middle .table-size {
    width: 40px;
    line-height: 40px;
    height: 40px;
    font-size: 12px !important;
  }

  .hide-mobile-info-order {
    display: none;
  }

  .order-product-info {
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .input-data-wrapper .user-data .wrap-checkout-elements,
  .input-data-wrapper .user-data .note,
  .input-data-wrapper .checkout-btn {
    width: 100% !important;
  }
}
